import get from 'lodash.get';

export const testimonials = (nodeData, fieldName: string) => {
  const data = get(nodeData, `${fieldName}.entity`, undefined);
  const variant = get(data, 'fieldVariantQuote', {})

  let items
  if (variant === '1_quote_image' || variant === '1_quote') {
    items = [getItem(data)]
  } else {
    items = [getItem(data), getItem(data, 2)]
  }

  const mappedData = {
    type: get(data, 'entityBundle', 'testimonials'),
    background: get(data, 'fieldBackground[0]'),
    headline: get(data, 'fieldHeadline.processed', ''),
    variant: variant,
    image: {
      alt: get(data, 'fieldImage.Image.alt', ''),
      url: get(data, 'fieldImage.url', ''),
      title: get(data, 'fieldImage.title', ''),
      webp: get(data, '.fieldImage.derivative.url', ''),
    },
    items
  }
  return mappedData
}

export const getItem = function (data, no: number | string = '') {
  return {
    content: get(data, `fieldTestimonialCopy${no}.processed`),
    person: get(data, `fieldTestimonialHeadline${no}.processed`, ''),
    logo: {
      alt: get(data, `fieldTestimonialSubImage${no}.alt`, ''),
      url: get(data, `fieldTestimonialSubImage${no}.url`, ''),
      title: get(data, `fieldTestimonialSubImage${no}.title`, ''),
      webp: get(data, `fieldTestimonialSubImage${no}.derivative.url`, ''),
    }
  }
}
