import React from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Layout from '@components/layout';
import PageComponents from '@components/layout/PageComponents/PageComponents';

// === Content === //
import { ProductCategoryPageDTO } from '@DTO/ProductCategoryDTO';

// === Helpers === //
import { breadcrumbsObject, organizationObject } from '@helpers/schemaOrg';

// === Types === //
import { TemplatePageContextData } from '@type/index.d';

interface ProductCategoryPageProps {
  pageContext?: TemplatePageContextData;
}

const ProductCategoryPage = ({ pageContext }: ProductCategoryPageProps) => {
  const productCategoryPage = ProductCategoryPageDTO(pageContext?.uuid);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbsObject(productCategoryPage.breadcrumbs.list))}
        </script>
        <script type="application/ld+json">{JSON.stringify(organizationObject())}</script>
      </Helmet>
      <Layout
        metaData={productCategoryPage.metaData}
        breadcrumbs={productCategoryPage.breadcrumbs}
        stickedForm={productCategoryPage.stickedForm}
        bannerContactInfo={undefined}
        navLangPath={productCategoryPage.langPath}
      >
        <PageComponents
          components={productCategoryPage.components}
          slug={`/${pageContext?.slug || ''}`}
        />
      </Layout>
    </>
  );
};

export default ProductCategoryPage;
